import React, { useState, useEffect } from "react";
import BingoTable from "../components/BingoTable";
import LastAction from "../components/LastAction";
import ControlToyButton from '../components/ControlToyButton';
import axios from 'axios';
import SlotMachine from "../components/SlotMachine";

const Settings = () => {
    const [numberInput, setNumberInput] = useState('');
    const [tasks, setTasks] = useState([
        { task: "Wink my eye", small: true, bingo: false, board: false },
        { task: "Send kiss", small: true, bingo: false, board: false },
        { task: "Bite my lip", small: true, bingo: false, board: false },
        { task: "Touch my tits", small: true, bingo: false, board: false },
        { task: "Bounce tits", small: true, bingo: false, board: false },
        { task: "Show boobs", small: true, bingo: false, board: false },
        { task: "Stand up", small: true, bingo: false, board: false },
        { task: "Touch slowly my body", small: true, bingo: false, board: false },
        { task: "Spank ass 5 times", small: true, bingo: false, board: false },
        { task: "Hand bra", small: true, bingo: false, board: false },
        { task: "10 Squats", small: true, bingo: false, board: false },
        { task: "10 Jumps", small: true, bingo: false, board: false },
        { task: "Show doggy", small: true, bingo: false, board: false },

        { task: "Rub nipples", small: false, bingo: true, board: false },
        { task: "Cameltoe", small: false, bingo: true, board: false },
        { task: "Suck finger and rub nipple", small: false, bingo: true, board: false },
        { task: "Oil tits", small: false, bingo: true, board: false },
        { task: "Oil ass", small: false, bingo: true, board: false },
        { task: "Twerk", small: false, bingo: true, board: false },
        { task: "Dance one song", small: false, bingo: true, board: false },
        { task: "Flash pussy", small: false, bingo: true, board: false },
        { task: "Bra off 20min", small: false, bingo: true, board: false },
        { task: "Panties off 20min", small: false, bingo: true, board: false },
        { task: "Wear dress", small: false, bingo: true, board: false },
        { task: "Wear skirt", small: false, bingo: true, board: false },
        { task: "Wear dress without panties", small: false, bingo: true, board: false },
        { task: "Wear skirt without panties", small: false, bingo: true, board: false },
        { task: "Wear only apron", small: false, bingo: true, board: false },

        { task: "Bottomless all stream", small: false, bingo: true, board: false },
        { task: "Topless all stream", small: false, bingo: true, board: false },

        { task: "Hitachi on nipples 5min", small: false, bingo: true, board: true },
        { task: "Put tail plug in", small: false, bingo: true, board: true },
        { task: "Keep dildo 5min inside", small: false, bingo: true, board: true },
        { task: "Naked all stream", small: false, bingo: true, board: true },

        { task: "Hitachi on clit 5min", small: false, bingo: false, board: true },
        { task: "Cum show", small: false, bingo: false, board: true },
        { task: "Squirt", small: false, bingo: false, board: true },
        { task: "Sensual blowjob 5min", small: false, bingo: false, board: true },
        { task: "Dildo till cum", small: false, bingo: false, board: true },
        { task: "Naked oil show", small: false, bingo: false, board: true },
        { task: "Roll a dice and cum that often", small: false, bingo: false, board: true },
    ]);

    const handleNumberChange = (e) => {
        setNumberInput(e.target.value);
    };

    const handleCheckboxChange = (index, name) => {
        setTasks(prevTasks =>
            prevTasks.map((task, i) =>
                i === index ? { ...task, [name]: !task[name] } : task
            )
        );
    };

    const handleSubmit = () => {
        const selectedSmallTasks = tasks.filter(task => task.small).map(task => task.task).join(", ");
        const selectedBingoTasks = tasks.filter(task => task.bingo).map(task => task.task).join(", ");
        const selectedBoardTasks = tasks.filter(task => task.board).map(task => task.task).join(", ");

        const data = {
            number: numberInput,
            texts: {
                smallTask: selectedSmallTasks,
                bingoTask: selectedBingoTasks,
                boardClearedTask: selectedBoardTasks
            }
        };

        axios.post('https://kate.homeserver.dns64.de:7777/api/settings', data)
            .then(response => {
                console.log(response.data);
            })
            .catch(error => {
                console.error("There was an error sending the data!", error);
            });
    };

    return (
        <div className="App">
            <BingoTable />
            <LastAction />
            <div>
                <label>
                    Number:
                    <input type="number" value={numberInput} onChange={handleNumberChange} />
                </label>
            </div>

            <div style={{alignContent: "center"}}>
                <h2>Task List</h2>
                <table style={{ borderCollapse: "collapse", width: "50%", alignContent: "center" }}>
                    <thead>
                    <tr>
                        <th style={{ border: "1px solid black", padding: "8px" }}>Task</th>
                        <th style={{ border: "1px solid black", padding: "8px" }}>Small</th>
                        <th style={{ border: "1px solid black", padding: "8px" }}>Bingo</th>
                        <th style={{ border: "1px solid black", padding: "8px" }}>Board</th>
                    </tr>
                    </thead>
                    <tbody>
                    {tasks.map((task, index) => (
                        <tr key={index}>
                            <td style={{ border: "1px solid black", padding: "8px" }}>{task.task}</td>
                            <td style={{ border: "1px solid black", textAlign: "center" }}>
                                <input
                                    type="checkbox"
                                    checked={task.small}
                                    onChange={() => handleCheckboxChange(index, 'small')}
                                />
                            </td>
                            <td style={{ border: "1px solid black", textAlign: "center" }}>
                                <input
                                    type="checkbox"
                                    checked={task.bingo}
                                    onChange={() => handleCheckboxChange(index, 'bingo')}
                                />
                            </td>
                            <td style={{ border: "1px solid black", textAlign: "center" }}>
                                <input
                                    type="checkbox"
                                    checked={task.board}
                                    onChange={() => handleCheckboxChange(index, 'board')}
                                />
                            </td>
                        </tr>
                    ))}
                    </tbody>
                </table>
            </div>

            <button onClick={handleSubmit}>Submit</button>

            <div>
                <h1>Vibrate Strength 4, Duration 5 seconds</h1>
                <ControlToyButton action="Vibrate" strength={4} duration={5}/>
                <h1>Vibrate Strength 8, Duration 5 seconds</h1>
                <ControlToyButton action="Vibrate" strength={8} duration={5}/>
                <h1>Vibrate Strength 12, Duration 5 seconds</h1>
                <ControlToyButton action="Vibrate" strength={12} duration={5}/>
                <h1>Vibrate Strength 14, Duration 5 seconds</h1>
                <ControlToyButton action="Vibrate" strength={14} duration={5}/>
                <h1>Vibrate Strength 16, Duration 5 seconds</h1>
                <ControlToyButton action="Vibrate" strength={16} duration={5}/>
                <h1>Vibrate Strength 20, Duration 5 seconds</h1>
                <ControlToyButton action="Vibrate" strength={20} duration={5}/>
            </div>
        </div>
    );
};

export default Settings;
