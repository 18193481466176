import logo from './logo.svg';
import './App.css';
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";

import Board from "./pages/board";
import Settings from "./pages/settings";
import Luca from "./pages/luca";

function App() {
  return (
    <div className="App">
        <Router>
            <Routes>
                <Route path="/" element={<Settings/>} />
                <Route path="/settings" element={<Settings/>} />
                <Route path="/board" element={<Board/>} />
                <Route path="/luca" element={<Luca/>} />
            </Routes>
        </Router>
    </div>
  );
}


export default App;
