import React, { useState, useEffect } from "react";
import BingoTable from "../components/BingoTable";
import LastAction from "../components/LastAction";
import axios from 'axios';

const Settings = () => {
    const [numberInput, setNumberInput] = useState('');
    const [textInputs, setTextInputs] = useState({
        smallTask: '',
        bingoTask: '',
        boardClearedTask: ''
    });

    const [taskInputs, setTaskInputs] = useState({
        smallTask: '',
        bingoTask: '',
        boardClearedTask: ''
    });

    const [tasks, setTasks] = useState({
        smallTasks: [],
        bingoTasks: [],
        boardClearedTasks: []
    });

    useEffect(() => {
        axios.get('https://kate.homeserver.dns64.de:7777/api/tasks')
            .then(response => {
                const { smallTasks, bingoTasks, boardClearedTasks } = response.data;
                setTasks({
                    smallTasks,
                    bingoTasks,
                    boardClearedTasks
                });
            })
            .catch(error => {
                console.error("There was an error fetching the tasks!", error);
            });
    }, []);

    const handleNumberChange = (e) => {
        setNumberInput(e.target.value);
    };

    const handleTextChange = (e) => {
        const { name, value } = e.target;
        setTextInputs(prevState => ({
            ...prevState,
            [name]: value
        }));
    };

    const handleTaskChange = (e) => {
        const { name, value } = e.target;
        setTaskInputs(prevState => ({
            ...prevState,
            [name]: value
        }));
    };

    const handleSubmit = () => {
        const data = {
            number: numberInput,
            texts: textInputs
        };

        axios.post('https://kate.homeserver.dns64.de:7777/api/settings', data)
            .then(response => {
                console.log(response.data);
            })
            .catch(error => {
                console.error("There was an error sending the data!", error);
            });
    };

    const addTask = (type) => {
        const task = taskInputs[`${type}Task`];
        if (!task) return;

        axios.post('https://kate.homeserver.dns64.de:7777/api/task/add', { type, task })
            .then(response => {
                console.log(response.data);
                setTasks(response.data.tasks);
                setTaskInputs(prevInputs => ({
                    ...prevInputs,
                    [`${type}Task`]: ''
                }));
            })
            .catch(error => {
                console.error("There was an error adding the task!", error);
            });
    };

    const removeTask = (type, task) => {
        axios.post('https://kate.homeserver.dns64.de:7777/api/task/remove', { type, task })
            .then(response => {
                console.log(response.data);
                setTasks(response.data.tasks);
            })
            .catch(error => {
                console.error("There was an error removing the task!", error);
            });
    };

    return (
        <div className="App">
            <BingoTable/>
            <LastAction/>
            <div>
                <label>
                    Number:
                    <input type="number" value={numberInput} onChange={handleNumberChange}/>
                </label>
                <div>
                    <label>
                        Small Task:
                        <input type="text" name="smallTask" value={textInputs.smallTask} onChange={handleTextChange}/>
                    </label>
                </div>
                <div>
                    <label>
                        Bingo Task:
                        <input type="text" name="bingoTask" value={textInputs.bingoTask} onChange={handleTextChange}/>
                    </label>
                </div>
                <div>
                    <label>
                        Board Cleared Task:
                        <input type="text" name="boardClearedTask" value={textInputs.boardClearedTask}
                               onChange={handleTextChange}/>
                    </label>
                </div>
                <button onClick={handleSubmit}>Submit</button>
            </div>
            <div>
                <h2>Add Task</h2>
                <div>
                    <label>
                        Small Task:
                        <input type="text" name="smallTask" value={taskInputs.smallTask} onChange={handleTaskChange}/>
                    </label>
                    <button onClick={() => addTask('small')}>Add Small Task</button>
                </div>
                <div>
                    <label>
                        Bingo Task:
                        <input type="text" name="bingoTask" value={taskInputs.bingoTask} onChange={handleTaskChange}/>
                    </label>
                    <button onClick={() => addTask('bingo')}>Add Bingo Task</button>
                </div>
                <div>
                    <label>
                        Board Cleared Task:
                        <input type="text" name="boardClearedTask" value={taskInputs.boardClearedTask}
                               onChange={handleTaskChange}/>
                    </label>
                    <button onClick={() => addTask('boardCleared')}>Add Board Cleared Task</button>
                </div>
            </div>
            <div>
                <h2>Current Tasks</h2>
                <div>
                    <h3>Small Tasks</h3>
                    <ul>
                        {tasks.smallTasks.map((task, index) => (
                            <li key={index}>
                                {task}
                                <button onClick={() => removeTask('small', task)}>Remove</button>
                            </li>
                        ))}
                    </ul>
                </div>
                <div>
                    <h3>Bingo Tasks</h3>
                    <ul>
                        {tasks.bingoTasks.map((task, index) => (
                            <li key={index}>
                                {task}
                                <button onClick={() => removeTask('bingo', task)}>Remove</button>
                            </li>
                        ))}
                    </ul>
                </div>
                <div>
                    <h3>Board Cleared Tasks</h3>
                    <ul>
                        {tasks.boardClearedTasks.map((task, index) => (
                            <li key={index}>
                                {task}
                                <button onClick={() => removeTask('boardCleared', task)}>Remove</button>
                            </li>
                        ))}
                    </ul>
                </div>
            </div>
        </div>
    );
};

export default Settings;
