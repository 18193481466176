import React, { useState } from 'react';

// Possible options for each wheel
const gameTypes = ["2 vs 2", "3 vs 1", "4 player"];
const miniGames = ["Tag", "Capture the Flag", "Hide and Seek", "Trivia"];

function SlotMachine() {
    const [wheel1, setWheel1] = useState(gameTypes[0]);
    const [wheel2, setWheel2] = useState(miniGames[0]);
    const [isSpinning, setIsSpinning] = useState(false);
    const [spinTimeout, setSpinTimeout] = useState(null);

    // Spin the wheels
    const spin = () => {
        setIsSpinning(true);

        // Set random values to make it appear as spinning
        let wheel1Cycle = setInterval(() => {
            setWheel1(gameTypes[Math.floor(Math.random() * gameTypes.length)]);
        }, 100); // Change option every 100ms

        let wheel2Cycle = setInterval(() => {
            setWheel2(miniGames[Math.floor(Math.random() * miniGames.length)]);
        }, 100); // Change option every 100ms

        // Stop spinning after 1.5 seconds and show final result
        setSpinTimeout(
            setTimeout(() => {
                clearInterval(wheel1Cycle);
                clearInterval(wheel2Cycle);

                // Show final values after 1.5 seconds
                setWheel1(gameTypes[Math.floor(Math.random() * gameTypes.length)]);
                setWheel2(miniGames[Math.floor(Math.random() * miniGames.length)]);
                setIsSpinning(false); // Stop spinning
            }, 1500) // Stop after 1.5 seconds
        );
    };

    // Cleanup timeout if component is unmounted
    React.useEffect(() => {
        return () => {
            if (spinTimeout) clearTimeout(spinTimeout);
        };
    }, [spinTimeout]);

    return (
        <div style={styles.slotMachine}>
            <div style={styles.wheels}>
                <div
                    style={styles.wheel}
                    className={isSpinning ? 'spinning' : ''}
                >
                    <div style={styles.scrollContainer}>
                        {gameTypes.concat(gameTypes).map((item, index) => (
                            <div key={index} style={styles.option}>
                                {item}
                            </div>
                        ))}
                    </div>
                </div>
                <div
                    style={styles.wheel}
                    className={isSpinning ? 'spinning' : ''}
                >
                    <div style={styles.scrollContainer}>
                        {miniGames.concat(miniGames).map((item, index) => (
                            <div key={index} style={styles.option}>
                                {item}
                            </div>
                        ))}
                    </div>
                </div>
            </div>
            <button style={styles.button} onClick={spin} disabled={isSpinning}>
                {isSpinning ? "Spinning..." : "Spin"}
            </button>
        </div>
    );
}

// Styles
const styles = {
    slotMachine: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        fontFamily: 'sans-serif',
        padding: '20px',
    },
    wheels: {
        display: 'flex',
        justifyContent: 'center',
        marginBottom: '20px',
    },
    wheel: {
        margin: '0 10px',
        padding: '20px 30px',
        fontSize: '24px',
        border: '1px solid #ccc',
        borderRadius: '5px',
        minWidth: '150px',
        height: '80px',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        background: '#f3f3f3',
        position: 'relative',
        overflow: 'hidden',
    },
    scrollContainer: {
        display: 'flex',
        flexDirection: 'column',
        transition: 'transform 1.5s ease-in-out', // Smooth scrolling transition
    },
    option: {
        padding: '10px 0',
        textAlign: 'center',
    },
    button: {
        padding: '10px 20px',
        fontSize: '18px',
        borderRadius: '5px',
        cursor: 'pointer',
    },
};

// CSS for the animation
const styleSheet = document.styleSheets[0];

styleSheet.insertRule(` 
  .spinning .scrollContainer {
    animation: scrollAnimation 1.5s ease-in-out;
  }
`, styleSheet.cssRules.length);

export default SlotMachine;
