import React from 'react';

function ControlToyButton({ action, strength, duration }) {
    // The function that will be triggered when the button is clicked
    const handleButtonClick = async () => {
        const url = `https://127-0-0-1.lovense.club:30010/command`;

        // Define the payload based on the parameters provided
        const payload = {
            command: "Function",
            action: `${action}:${strength}`,
            timeSec: duration,
            apiVer: 1
        };

        try {
            // Send POST request to the Lovense Connect API
            const response = await fetch(url, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(payload)
            });

            if (!response.ok) {
                throw new Error('Network response was not ok');
            }

            const data = await response.json();
            console.log('Toy control response:', data);

            if (data.code === 200) {
                alert('Toy command sent successfully!');
            } else {
                alert(`Error: ${data.type}`);
            }

        } catch (error) {
            console.error('Error sending request:', error);
            alert('Failed to send request to the toy.');
        }
    };

    return (
        <button onClick={handleButtonClick}>
            Activate Toy
        </button>
    );
}

export default ControlToyButton;
